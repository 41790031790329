
    #result{
      border: 1px solid rgba(255,255,255,.125);
      background-color: rgba(255,255,255,.125);
      box-shadow: 0 2px 10px rgb(0 0 0 / 17%);
      color: #000000;
      max-height: 470px;
      min-height: 400px;
      padding: 15px;
      border-radius: 4px;
    }
    .btn-block-record{
      background : linear-gradient(to right, #C04848 0%, #480048  51%, #C04848  100%);
      border-color: transparent;
    }
    .btn-block-record:hover, .btn-block-record:hover i{
      /*background-color: #bd2130;*/
      background-color: #bd2130;
      border-color: #bd2130;
      box-shadow: 0 0.125rem 0.25rem rgb(0 0 0 / 8%);
    }
    .btn-block-record:hover i{box-shadow: none;border-color: transparent;
       background-color: transparent;}
    .btn-block-record i{
       font-size: 50px;
       box-shadow: none;
       border-color: transparent;
       background-color: transparent;
    }
    .btn-block-record i:hover{
      font-size: 50px;
      /*#de2935*/
      background-color:transparent;
      border-color: transparent;
      box-shadow: none;
    }
    .editBtnSection{
      position: absolute;
      right: 20px;
      top: 7px;
  }
   
 .box{
  position:relative;
  min-height: 100px;
}

.circle_ripple.active{
  height: 60px;
  width: 60px;
  background: #36B37E;
  border-radius: 50%;
  -webkit-animation-name: ripple 2s infinite;
  animation: ripple 2s infinite;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  z-index: 0;
  bottom: 0;
  margin: auto;
}
.circle_ripple-2.active{
  height:60px;
  width:60px;
  background:#36B37E;
  border-radius:50%;
  -webkit-animation-name: ripple 2s infinite; 
  animation: ripple-2 2s infinite;
  position:absolute;
  left: 0;
  top: 0;
  right: 0;
  /*z-index: 0;*/
  bottom: 0;
  margin: auto;
}

@keyframes ripple {
 0% {
transform:scale(1);
  }
  50% {
transform:scale(2);
    opacity:0.3;
  }
 100% {
transform:scale(1);
   
  }  
}
@keyframes ripple-2 {
 0% {
transform:scale(1);
  }
  50% {
transform:scale(2.5);
    opacity:0.3;
  }
 100% {
transform:scale(1);
  }  
}

.circles{
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    padding: 26px;
    z-index: 99;
}
 
.circles-2 .fa{
  font-size:50px;
  color:#fff;
  z-index: 99;
}
#note-textarea{
  height:100%; background: transparent; border: transparent;    width: 100%; padding: 5px 10px;
}
 
#notes{padding: 0;}
#notes .note{
  list-style: none;
}
#notes table{
  width: 100%;
}