.animate-menu-push {
	left: 0;
	position: relative;
	transition: all 0.3s ease;
}
.animate-menu-push.animate-menu-push-right {
	left: 200px;
}
.animate-menu-push.animate-menu-push-left {
	left: -200px;
}
.animate-menu {
	position: fixed;
	top: 0;
	width: 200px;
	height: 100%;
	transition: all 0.3s ease;
}
.animate-menu-left {
	left: -200px;
}
.animate-menu-left.animate-menu-open {
	left: 0;
}
.animate-menu-right {
	right: -200px;
}
.animate-menu-right.animate-menu-open {
	right: 0;
}
.sidebar-menu {
	list-style: none;
	margin: 0;
	padding: 0;
	background-color: transparent;
}
.sidebar-menu>li {
	position: relative;
	margin: 0;
	padding: 0;
}
.sidebar-menu>li>a {
	padding: 13px 5px 13px 15px;
	display: block;
	border-left: 3px solid transparent;
	color: #000;
	font-size: 15px;
}
.sidebar-menu>li>a>.fa {
	width: 20px;
}
.sidebar-menu>li:hover>a, .sidebar-menu>li.active>a {
	color: #fff;
    background: linear-gradient(to right, #C04848 0%, #480048  51%, #C04848  100%);
    border-left-color: #ffffff;
}
.sidebar-menu>li .label, .sidebar-menu>li .badge {
	margin-top: 3px;
	margin-right: 5px;
}
.sidebar-menu li.sidebar-header {
	padding: 10px 25px 10px 15px;
	font-size: 12px;
	color: #000;
}
.sidebar-menu li>a>.fa-angle-left {
	width: auto;
	height: auto;
	padding: 0;
	margin-right: 10px;
	margin-top: 3px;
}

.sidebar-menu li.active>a>.fa-angle-left {
	transform: rotate(-90deg);
}

.sidebar-menu li.active>.sidebar-submenu {
	display: block;
}
.sidebar-menu a {
	color: #b8c7ce;
	text-decoration: none;
}
.sidebar-menu .sidebar-submenu {
	display: none;
	list-style: none;
	padding-left: 5px;
	margin: 0 1px;
	background: transparent;
}
.sidebar-menu .sidebar-submenu .sidebar-submenu {
	padding-left: 20px;
}
.sidebar-menu .sidebar-submenu>li>a {
	padding: 5px 5px 5px 15px;
	display: block;
	font-size: 14px;
	color: rgba(255, 255, 255, 0.65);
}
.sidebar-menu .sidebar-submenu>li>a>.fa {
	width: 20px;
	font-size: 13px;
}
.sidebar-menu .sidebar-submenu>li>a>.fa-angle-left, .sidebar-menu .sidebar-submenu>li>a>.fa-angle-down {
	width: auto;
}
.sidebar-menu .sidebar-submenu>li.active>a, .sidebar-menu .sidebar-submenu>li>a:hover {
	color: #ffffff;
}
.sidebar-menu-rtl {
	list-style: none;
	margin: 0;
	padding: 0;
	background-color: #222d32;
}
.sidebar-menu-rtl>li {
	position: relative;
	margin: 0;
	padding: 0;
}
.sidebar-menu-rtl>li>a {
	padding: 12px 15px 12px 5px;
	display: block;
	border-left: 3px solid transparent;
	color: #b8c7ce;
}
.sidebar-menu-rtl>li>a>.fa {
	width: 20px;
}
.sidebar-menu-rtl>li:hover>a, .sidebar-menu-rtl>li.active>a {
	color: #fff;
	background: #1e282c;
	border-left-color: #3c8dbc;
}
.sidebar-menu-rtl>li .label, .sidebar-menu-rtl>li .badge {
	margin-top: 3px;
	margin-right: 5px;
}
.sidebar-menu-rtl li.sidebar-header {
	padding: 10px 15px 10px 25px;
	font-size: 12px;
	color: #4b646f;
	background: #1a2226;
}
.sidebar-menu-rtl li>a>.fa-angle-left {
	width: auto;
	height: auto;
	padding: 0;
	margin-right: 10px;
	margin-top: 3px;
}
.sidebar-menu-rtl li.active>a>.fa-angle-left {
	transform: rotate(-90deg);
}
.sidebar-menu-rtl li.active>.sidebar-submenu {
	display: block;
}
.sidebar-menu-rtl a {
	color: #b8c7ce;
	text-decoration: none;
}
.sidebar-menu-rtl .sidebar-submenu {
	display: none;
	list-style: none;
	padding-right: 5px;
	margin: 0 1px;
	background: #2c3b41;
}
.sidebar-menu-rtl .sidebar-submenu .sidebar-submenu {
	padding-right: 20px;
}
.sidebar-menu-rtl .sidebar-submenu>li>a {
	padding: 5px 15px 5px 5px;
	display: block;
	font-size: 14px;
	color: #8aa4af;
}
.sidebar-menu-rtl .sidebar-submenu>li>a>.fa {
	width: 20px;
}
.sidebar-menu-rtl .sidebar-submenu>li>a>.fa-angle-left, .sidebar-menu-rtl .sidebar-submenu>li>a>.fa-angle-down {
	width: auto;
}
.sidebar-menu-rtl .sidebar-submenu>li.active>a, .sidebar-menu-rtl .sidebar-submenu>li>a:hover {
	color: #fff;
}