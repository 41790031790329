:root{
  --primary-color:linear-gradient(to right, #C04848 0%, #480048  51%, #C04848  100%);
  --bg-color: #dfe6e9;
  --text-color: white;
}
      #root {
        width: 100%;
      }
      .dashboard{
        height: 100vh;
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: 'Nunito Sans', sans-serif;
      }

      .dashboard h1{text-align: center;text-transform: uppercase;font-weight: 700 !important; font-size: 28px !important; margin: 0; font-family: monospace;}

      .dashboard .container{
        background: white;
        cursor: pointer;
        border-radius: 15px;
        position: relative;
        padding: 25px 30px;
        color: var(--primary-color);
      }

      .dashboard .container::after{
        content: '';
        background: var(--primary-color);
        border-radius: 15px;
        height: 100%;
        width: 100%;
        position: absolute;
        left: 0;
        top: 0;
        z-index: 0;
        clip-path: circle(10% at 0% 0%);           transition: all .3s ease-in;

      }
      .dashboard .container:hover{
        transform: translateY(-5px);
        transition: transform 250ms;
      }
      .dashboard .container:hover .content i{color: #fff}

      .dashboard .content{
        position: relative;
        z-index: 1;
        transition: all .3s ease-in;
      }

      .dashboard .container:hover::after{
        clip-path: circle(100%);
      }

      .dashboard .container:hover .content, .dashboard .container:hover .content h1{
        color: var(--text-color);
      }
      .dashboard .content i{
        color: #C04848;
        font-size: 30px;
        opacity: 0.5;
      }